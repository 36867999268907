<template>
  <div class="scan">
    <div class="mask"></div>
    <div class="item-wrap">
      <div class="img-pay">
        <div class="title">{{type}}扫码支付<em ></em></div>
        <div class="qrcode"><img :src="img"></div>
        <div class="centerbox">
          <div class="tip" @click="close">  确定 </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name:'scan-pay-code',
    // props:['img'],
    props: {
      img: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: '微信'  // 微信，支付宝
      },
    },
    methods:{
      open(){

      },
      close(){
        this.$emit('close');
      }
    }
  }
</script>
<style lang="scss">
  @import './../assets/scss/config.scss';
  @import './../assets/scss/mixin.scss';
  .scan{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    .mask{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .6;
      background-color: #000000;
    }
    .item-wrap{
      position: fixed;
      // width:670px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      align-items: center;
      .img-pay{
        width: 370px;
        height: 440px;
        display: inline-block;
        background-color:#ffffff;
        .title{
          position:relative;
          height:60px;
          line-height:60px;
          color:#333333;
          font-size:20px;
          padding:0 18px;
          background-color:#F5F5F5;
          em{
            position:absolute;
            top: 23.5px;
            right: 20px;
            @include bgImg(13px,13px,'/imgs/icon-close.png');
            cursor:pointer;
          }
        }
        .qrcode{
          text-align: center;
          padding:44px 0 26px;
          img{
            width:237px;
            height:240px;
          }
        }
        .centerbox{
          display: flex;
          justify-content: center;
        }
        .tip{
          cursor: pointer;
          font-size:14px;
          color:#333333;
          width:90px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #f33a35;
          color: #fff;
          border-radius: 4px;
        }
      }
    }
  }
</style>