<template>
  <div class="member">
    <div class="topBox">
      <img class="topBg" src="@/assets/img/userBg.png" alt="" />
      <div class="topText flexbox">
        <img class="userImg" :src="info.photo" alt="" />
        <div>
          <div class="name flexbox">
            {{ispass==true ? info.qiyeName : info.nikname}} <img src="@/assets/img/vip.png" alt="" class="vipimg" v-if="qiyevip"/>
          </div>
          <div class="date" v-if="qiyevip"> {{ info.membersType }}会员 到期时间：{{ info.membersTime }}</div>
					<div class="date" v-else> 非会员</div>
        </div>
      </div>
    </div>
    <div class="cardBox">
      <div class="title">会员卡类型</div>

      <div class="cardcard">
        <div
          class="card"
          :class="{ active: index == cardNum }"
          v-for="(item, index) in card"
          :key="index"
          @click="acrdFun(index)"
        >
          <div class="">
            <div class="name">{{ item.type }}会员</div>
            <div class="price"><span>¥</span>{{ item.price }}</div>
            <div class="time">有效期{{ item.days }}天</div>
          </div>
        </div>
      </div>

      <div class="warn" v-html="content"></div>

      <div class="title payTitle">支付方式</div>
      <div style="display: flex">
        <div
          class="payBox flex_center"
          :class="{ choose: payId == way.id }"
          @click="payWay(way)"
          v-for="(way, i) in payMethod"
          :key="i"
        >
          <img :src="way.src" alt="" />
          {{ way.name }}
        </div>
      </div>
			<div v-if="payId == 3">
				<div class="title">上传转账截图</div>
				<div class="upLoad">
					
					<el-upload
          class="avatar-uploader"
          action
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :http-request="
            (file) => {
              uploadSectionFile(file)
            }
          "
        >
					<img
						width="133px"
						height="133px"
						src="@/assets/img/upLoad_.png"
						alt=""
						v-if="!photo"
					/>
				<img :src="photo" alt="" v-if="photo" 	width="133px" height="133px"/>
        </el-upload>
				</div>
			</div>
     
      <div class="btn1" @click="submitPay()">立即开通</div>
    </div>

    <!-- 不是企业会员弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="375px"
      :before-close="handleClose"
      center>
    <span>您暂不是企业用户，无法开通会员，请认证后操作</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click.stop="tourl" class="buttonbtncss">去认证</el-button>
  </span>
</el-dialog>

		<!-- 微信支付二维码弹窗 -->
    <scan-pay-code v-if="showPay"  @close="closePayModal" :img="payImg"></scan-pay-code>
  </div>
</template>

<script>
import base from '@/config/env' // 引入域名
import apiUrl from '@/api/main'
import apiUrlLogin from '@/api/login'
import ScanPayCode from '@/components/ScanPayCode';

export default {
  components: {ScanPayCode},
  data() {
    return {
      cardNum: 0,
      card: [],
      payId: 1,
      payMethod: [
        {
          id: 1,
          src: require('@/assets/img/pay_wx.png'),
          name: '微信支付',
          type: 'wechat'
        },
        {
          id: 2,
          src: require('@/assets/img/pay_zfb.png'),
          name: '支付宝支付',
          type: 'alipay'
        },
        {
          id: 3,
          src: require('@/assets/img/card.png'),
          name: '线下转账',
          type: 'other'
        }
      ],
      info: {},
      content: '', // 权益
			photo:'', // 转账凭证
			cardId:'',
			payMoney:'',
			showPay:false, // 微信支付弹窗
			payImg:'', // 支付二维码
			qiye:false, // 是否是企业
			qiyevip:false, // 是否是企业vip
			ispass:false, // 是否企业认证成功
      dialogVisible: false,
      orderid:'', // 会员订单id

    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getdetails()
    this.cardinfo()
  },
  methods: {
		handleAvatarSuccess(res, file) {
      this.photo = URL.createObjectURL(file.raw)
    },
		uploadSectionFile(param) {
      var fileObj = param.file
      var from = new FormData();
      from.append('file', fileObj);

      apiUrlLogin.upload(from).then((res) => {
        if (res.code == 0) {
          this.$message.success('上传成功')
          this.photo = res.url;
        }
      })
    },
    payWay(way) {
      let that = this
      that.payId = way.id
    },
    /***
				获取会员详情 
			*/
    getdetails() {
      let data = {
        userId: localStorage.getItem('userId')
      }
      apiUrl.userInfo(data).then((res) => {
        if (res.code == 0) {
					this.info = res.data;
					// usertype 个人用户 企业用户
					if(res.data.usertype == '企业用户'){
							this.qiye = true;
							this.getqiye();
						}else{
							this.qiye = false;
						}
						// 是否会员 0否 1是
						if(res.data.usertype == '企业用户' && res.data.isMembers == 1){
							this.qiyevip = true;
						}else{
							this.qiyevip = false;
						}
        }
      })
    },
		
    // 获取企业详情 是否认证 3 未提交  0未审核,审核中  1审核通过  2拒绝 
    getqiye() {
      let data = {
        userId: localStorage.getItem('userId')
      }
      apiUrl.qiyeinfo(data).then((res) => {
        if(res.data){
					if(res.data.status == 1){
						this.ispass = true;
					}else {
						this.ispass = false;
					}
				} else {
					this.ispass = false;
				}
				
      })
    },
    // 会员卡信息
    cardinfo() {
      apiUrl.lsUserMembers().then((res) => {
        if (res.code == 0) {
          this.card = res.data
          this.content = res.data[0].content
          this.cardId = res.data[0].id
          this.payMoney = res.data[0].price
        }
      })
    },
   
    acrdFun(num) {
      this.cardNum = num;
      this.card.forEach((item, index) => {
        if (this.cardNum == index) {
          this.content = item.content
          this.cardId = item.id
          this.payMoney = item.price
        }
      })
    },
		// 立即开通 1微信 2 支付宝 3线下转账
		submitPay(){
      if(this.qiye == false){
        this.dialogVisible = true;
        return;
      }
      
			// 转账
			if(this.payId == 3 && !this.photo){
					this.$message({
						message:'请上传转账凭证',
						type:'error'
					});
					return;
			}

      let num = '';
      if(this.payId == 1){
        num = 7
      } else if(this.payId == 3){
        num = 3
      } else if(this.payId == 2){
        num = 8
      }

			let data = {
				content: this.cardId, // 开通会员列表id
				userId: localStorage.getItem('userId'), // 用户id
				payMoney: this.payMoney, // 支付金额
				payType: num, // 支付方式  this.payId 1微信  2支付宝 3转账
				zhuanzhuangphoto: this.photo, // 转账截图
			}
		
			apiUrl.userMembersorderpay(data).then((res) => {
       
				// 提交订单
				if(res.code == 0){
          if(this.payId == 3){
            this.$message.success('操作成功');
            this.getdetails();
          }
          if(this.payId == 1){
            this.showPay = true;
            this.orderid = res.lsUserMembersOrder.id
            // 拼接微信二维码
            this.payImg =  base.baseURL + 'api/getQRCode?codeUrl=' + res.orderString;
          }

          // 支付宝
          if(this.payId == 2){
            document.querySelector("body").innerHTML = res.orderString;
            document.forms[0].submit();
          }
				
				}
				
      })

		},
    // 关掉 微信支付弹窗
    closePayModal(){
      this.showPay = false;
      // 订单详情 /api/orderInfo    id 订单id   返回参数  payStatus支付状态 0未支付 1已支付
      let data = {
        id: this.orderid
      }

      apiUrl.userMembersorderInfo(data).then((res) => {
        if(res.data.payStatus == 1){
          this.$message({
            type: 'success',
            message: '充值成功'
          });
          this.getdetails();
        } else {
          this.$message({
            type: 'error',
            message: '支付失败'
          });
        }
         
      })
    },
    handleClose(){
      this.dialogVisible = false;
    },
    // 去认证
    tourl(){
      this.$router.push({
        path: '/authentify'
      });
    }
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.topBox {
  position: relative;
  .topBg {
    width: 100%;
  }
  .topText {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    color: #fff;
    padding: 42px 56px 54px;
    .userImg {
      width: 104px;
      height: 104px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      margin-right: 20px;
    }
    .name {
      font-size: 24px;
    }
    .date {
      font-size: 16px;
      margin-top: 20px;
    }
  }
}
.cardBox {
  background-color: #fff;
  border-radius: 17px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  padding: 30px 40px;
  margin-top: 30px;
 
  img {
    max-width: 100%;
  }
  .title {
    font-size: 20px;
    color: #333333;
    line-height: 23px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .cardcard{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 920px;
  }
  .card {
    cursor: pointer;
    width: 280px;
    height: 160px;
    padding: 20px 0;
    display: inline-block;
    text-align: center;
    background: #f8f9fd;
    border-radius: 8px;
    border: 2px solid #dfe4e9;
    margin-right: 40px;
    margin-bottom: 20px;
    // &:nth-of-type(2) {
    //   margin: 0 76px;
    // }
    &:nth-child(3n){
      margin-right: 0px;
    }
    .name {
      color: #646765;
      font-size: 15px;
    }
    .price {
      margin: 18px 0;
      font-size: 18px;
      font-weight: bold;
      font-size: 28px;
      span {
        font-size: 16px;
        padding-right: 6px;
      }
    }
    .time {
      color: #646765;
    }
  }
  .active {
    background: #fef6e9;
    border: 2px solid #e3bf99;
    .price {
      color: #e87400;
    }
  }
}
.warn {
  background: #fdf9f5;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 30px;
  color: #e87400;
  line-height: 25px;
  font-size: 15px;
  margin: 30px 0;
  width: 920px;
}
.payTitle {
  margin-top: 35px;
}
.payBox {
  margin-top: 15px;
  margin-bottom: 35px;
  width: 240px;
  height: 64px;
  background: #ffffff;
  border-radius: 7px;
  border: 2px solid transparent;
  background: #f5f5f5;
  cursor: pointer;
  font-size: 19px;
  img {
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
  &:nth-of-type(2) {
    margin-left: 24px;
    margin-right: 24px;
  }
}
.choose {
  border: 2px solid #e87400;
}
.upLoad {
  img {
    margin: 10px 5px 0 0;
    cursor: pointer;
  }
}
.btn1 {
  width: 500px;
  height: 67px;
  background: linear-gradient(90deg, #fe8313 0%, #f33a35 100%);
  border-radius: 33px;
  text-align: center;
  color: #fff;
  line-height: 67px;
  font-size: 20px;
  font-weight: bold;
  margin: 35px auto;
  cursor: pointer;
}
.vipimg {
  width: 65px;
  height: 30px;
  margin-left: 16px;
}
.buttonbtncss{
  width: 150px;
  height: 45px;
  background: linear-gradient(270deg, #4298FF 0%, #305BFE 100%);
  border-radius: 28px;
}
</style>